import React from "react";
import { BooleanField, FunctionField, TextField } from "react-admin";
import HotList from "../utils/components/HotList";
import DateField from "../utils/components/DateField";


export const PriceGroupList = ( props ) => (
  <HotList {...props}
           sort={"createdAt"}
           sortOrder={"DESC"}
           allowCreate={true}>
    <TextField source={"name"} label={"Name"}/>
    <TextField source={"description"} label={"Beschreibung"}/>
    <BooleanField source={"defaultPriceSet"} label={"Standard-Preisgruppe"}/>
    <BooleanField source={"active"} label={"Aktiv"}/>
    <DateField source={"validFrom"} label={"Gültig ab"} />
    <FunctionField label={"Kunden"} render={ record => {
      return record.customers.map( customer => {
        return customer.anzeigename
      }).join( ", " )
    }} />
  </HotList>
);


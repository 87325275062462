import React, { useState } from "react";
import { Button, FormWithRedirect, SaveButton, SaveContextProvider, SelectInput, useDataProvider, useNotify } from "react-admin";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import IconCancel from "@material-ui/icons/Cancel";
import { defaultButtonStyle } from "../../../utils/components/styles/ButtonStyles";
import PaletteIcon from '@material-ui/icons/Palette';
import { LightColorList } from "../../../utils/components/styles/Colors";

export const ChangeColorButton = ( { record, styles = {}, displayLabel = false, selectedContracts = [], setSelectedContracts, setKey } ) => {
  const [loading, setLoading] = useState( false );
  const [showDialog, setShowDialog] = useState( false );
  const dataProvider = useDataProvider();
  const notify = useNotify();

  if( selectedContracts?.length === 0 && !record ) {
    return null;
  }

  const buttonStyles = {
    ...defaultButtonStyle,
    ...styles
  };

  const onSave = async( values ) => {
    setLoading( true );
    let ids = selectedContracts
    if( record && selectedContracts.length === 0 ) {
      ids = [record[ '@id' ]];
    }

    for( let id of ids ) {
      dataProvider.update(
        "contract_avis",
        {
          id: id,
          data: {
            color: values?.color ?? null
          }
        }
      ).then( ( response ) => {
        notify( "Farbe erfolgreich geändert", { type: "success" } );

        if( selectedContracts && selectedContracts.length > 0 ) {
          const newSelectedContracts = new Set( selectedContracts );
          newSelectedContracts.delete( id );
          setSelectedContracts( newSelectedContracts );
        }
      } ).catch( ( error ) => {
        notify( `Error: ${error.message}`, { type: "warning" } );
      } )
    }


    setLoading( false );
    setShowDialog( false );
  };

  return (
    <>
      <FormWithRedirect
        resource="contracts"
        save={onSave}
        render={( { handleSubmitWithRedirect, pristine, saving } ) => {
          return (
            <SaveContextProvider>
              <Dialog fullWidth open={showDialog} onClose={() => setShowDialog( false )} maxWidth={"xs"}>
                <DialogTitle>Farbe wechseln</DialogTitle>
                <DialogContent>
                  <SelectInput
                    source="color"
                    record={record}
                    choices={LightColorList}
                    allowNull={true}
                    helperText={false}
                    emptyText={"Keine Farbe"}
                    emptyLabel={"Keine Farbe"}
                    allowEmpty={true}
                    label={"Farbe"}
                    fullWidth
                    resettable
                  />
                </DialogContent>
                <DialogActions>
                  <Button label="ra.action.cancel" onClick={() => setShowDialog( false )}>
                    <IconCancel/>
                  </Button>
                  <SaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    pristine={pristine}
                    saving={loading}
                  />
                </DialogActions>
              </Dialog>
              <Button
                style={buttonStyles}
                title={"Farbe wechseln"}
                label={displayLabel ? "Farbe wechseln" : ""}
                onClick={() => setShowDialog( true )}
                children={<PaletteIcon/>}
              />
            </SaveContextProvider>
          );
        }}
      />
    </>
  );
};

import List from "./EntityList";
import { EntityCreate, EntityEdit } from "./EntityEdit";
// import { EntityCreate, EntityEdit } from "./CustomerEdit";

const CustomerProtocol = {
  list: List,
  create: EntityCreate,
  edit: EntityEdit,
  name: "customer_protocols"
};

export default CustomerProtocol;

export const LightRed = "#FFCCCC";

export const Red = "#FF0000";

export const DarkRed = "#DC3545"

export const LightGreen = "#CCFFCC";
export const Green = "#00A65A";
export const DisabledGreen = "rgba(0,166,90,0.5)";

export const LightBlue = "#CCEEFF";

export const Blue='#2196f3';
export const DisabledBlue='rgba(33,150,243,0.5)';

export const LightPink = "#FFCCFF";

export const LightYellow = "#FFFFCC";

export const LightColorList = [
  { id: LightRed, name: 'Storno' },
  { id: LightYellow, name: 'Adressänderung folgt' },
  { id: LightGreen, name: 'Adressänderung eingetragen' },
  { id: LightBlue, name: 'Rückmeldung Dispo' },
  { id: LightPink, name: 'Erinnerung Office' },
]

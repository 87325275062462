import React, { useEffect } from "react";
import { Create, DeleteWithConfirmButton, Edit, TabbedForm } from "react-admin";
import createDecorator from "final-form-calculate";
import { Toolbar } from "@material-ui/core";
import DefaultEditActions from "../utils/DefaultEditActions";
import RefreshButton from "../utils/components/RefreshButton";
import { validateAdditionalRide } from "../utils/validators/DefaultValidators";
import SettingsIcon from "@material-ui/icons/Settings";
import EntityLink from "../utils/components/EntityLink";
import { CloneContractButton, ExportPDFButton } from "./components/ListButtons";
import { getGoogleDirections } from '../utils/request/PostRequest';
import * as ButtonStyles from "../utils/components/styles/ButtonStyles";
import { CustomSaveButton } from "./components/CustomSaveButton";
import CloneContractAsReturnContractButton from "./components/ListButtons/CloneContractAsReturnContractButton";
import HistoryTab from "./components/FormTabs/HistoryTab";
import ContractTab from "./components/FormTabs/ContractTab";


const formatTime = ( time ) => time.toLocaleTimeString( 'de-DE', { timeStyle: 'short' } );

const roundTimeToNearestMin = ( inDt, minutes = 30 ) => {
  const ms = 1000 * 60 * minutes;
  const outDt = new Date( Math.ceil( inDt.getTime() / ms ) * ms );

  return formatTime( outDt );
}

const formState = {
  isFinalFormHooksEnabled: false,
}

const calculator = ( isCreate ) => createDecorator(
  {
    field: [
      "pickupLocation.strasse",
      "pickupLocation.ort",
      "deliveryLocation.strasse",
      "deliveryLocation.ort",
      "serviceAddresses",
    ], // when address changes...
    // field: /\.strasse/,
    updates: async( value, name, allValues, prevValues ) => {
      const { pickupLocation, deliveryLocation, googleKm, googleFahrzeit, totalGooglefahrtzeitwert, serviceAddresses, editGoogleKm } =
        allValues;
      if( Object.keys( prevValues ).length === 0 ) {
        return { googleKm, googleFahrzeit, totalGooglefahrtzeitwert };
      }

      if(
        pickupLocation &&
        pickupLocation.strasse &&
        pickupLocation.ort &&
        deliveryLocation &&
        deliveryLocation.strasse &&
        deliveryLocation.ort &&
        !editGoogleKm && (
          pickupLocation.strasse !== prevValues.pickupLocation.strasse ||
          pickupLocation.ort !== prevValues.pickupLocation.ort ||
          deliveryLocation.strasse !== prevValues.deliveryLocation.strasse ||
          deliveryLocation.ort !== prevValues.deliveryLocation.ort
        )
      ) {


        const filteredServiceAddress = serviceAddresses ? serviceAddresses.map( ( serviceAddress ) => {
          if( serviceAddress && serviceAddress.city && serviceAddress.street && serviceAddress.houseNumber && serviceAddress.zipCode ) {
            return serviceAddress;
          }
        } ) : [];

        try {
          const { distanceKm, durationFormatted, durationSec } = await getGoogleDirections(
            pickupLocation,
            deliveryLocation,
            filteredServiceAddress
          );
          return {
            googleKm: distanceKm,
            googleFahrzeit: durationFormatted,
            totalGooglefahrtzeitwert: durationSec,
          };
        } catch( error ) {
          console.log( "Error getting distance", error );
          return {
            googleKm: null,
            googleFahrzeit: null,
            totalGooglefahrtzeitwert: null
          };
        }
      }

      return { googleKm, googleFahrzeit, totalGooglefahrtzeitwert };
    },
  },
  {
    field: ['totalGooglefahrtzeitwert', 'pickupTimeTo'],
    updates: ( value, name, allValues ) => {
      if( formState.isFinalFormHooksEnabled === false || !isCreate ) {
        return {};
      }
      const { pickupTimeTo, totalGooglefahrtzeitwert, datum, bringdatum } = allValues;
      if( !( pickupTimeTo && totalGooglefahrtzeitwert ) || new Date( datum ).valueOf() !== new Date( bringdatum ).valueOf() ) {
        return {};
      }

      const deliveryTimeFrom = new Date( `1970-01-01 ${pickupTimeTo}:00` );
      const offset = 30 * 60;

      deliveryTimeFrom.setSeconds( deliveryTimeFrom.getSeconds()+totalGooglefahrtzeitwert+offset );

      return {
        deliveryTimeFrom: roundTimeToNearestMin( deliveryTimeFrom )
      };
    }
  },
  {
    field: ['pickupTimeFrom', 'deliveryTimeFrom'],
    updates: ( value, name, allValues ) => {
      if( formState.isFinalFormHooksEnabled === false || !isCreate ) {
        return {};
      }
      const timeFrom = new Date( `1970-01-01 ${value}:00` );
      timeFrom.setHours( timeFrom.getHours()+2 );

      const timeTo = name.replace( 'From', 'To' );
      if( allValues[ name.replace( 'From', 'To' ) ] ) {
        return {};
      }

      return {
        [ timeTo ]: roundTimeToNearestMin( timeFrom )
      };
    }
  }
)

export const getFilter = ( source, filterBy ) => {
  if( source ) {
    if( typeof source === 'string' ) {
      return { [ filterBy ]: parseInt( source.split( '/' )[ 3 ] ) };
    }
    if( typeof source === 'object' && !Array.isArray( source ) && source[ '@id' ] ) {
      return { [ filterBy ]: parseInt( source[ '@id' ].split( '/' )[ 3 ] ) };
    }
  }

  return {};
}

const onFocus = () => {
  formState.isFinalFormHooksEnabled = true;
}

const ContractForm = ( props ) => {
  useEffect( () => {
    return () => {
      formState.isFinalFormHooksEnabled = false;
    }
  } );

  return (
    <TabbedForm
      decorators={[calculator( props.isCreate )]}
      validate={validateAdditionalRide}
      {...props}
    >
      <ContractTab onFocus={onFocus} isCreate={props.isCreate}/>
      <HistoryTab key={'historyTab'}/>
    </TabbedForm>
  );
};

const ContractToolbar = props => {
  const { isCreate } = props;

  return <Toolbar
    style={{
      position: "fixed",
      zIndex: 100000,
      bottom: 10,
      maxWidth: "100%",
      backgroundColor: "rgba(255,255,255, 0.8)",
      borderRadius: "5px",
    }}
  >
    {!props.isCreate && <CustomSaveButton isCreate={isCreate} {...props} redirect={true} label={"Speichern und schließen"}/>}
    <CustomSaveButton isCreate={isCreate} {...props} redirect={false} saveButtonProps={{id: 'submitFormButton'}}/>

    {props.record.createBundleWith && <CustomSaveButton isCreate={isCreate} {...props}
                                                        redirect={false}
                                                        createBundleWithContract={props.record.createBundleWith}
                                                        label={`Auftrag im Paket mit ${props.record.createBundleContractNumber} erstellen`}/>}

    {!props.isCreate && <DeleteWithConfirmButton
      confirmTitle={`Löschen bestätigen`}
      record={props.record}
      label={"Löschen"}
      confirmContent={`Möchten Sie den Auftrag ${props.record.auftragsnummer} wirklich löschen?`}
    />}

    <RefreshButton label="Zurücksetzen"/>
  </Toolbar>
}

const ContractEditActions = props => {
  return (
    <DefaultEditActions {...props}>
      <ExportPDFButton record={props?.data} style={ButtonStyles.noMargin} showRegenerate={false}/>
      <CloneContractButton record={props?.data}/>
      <CloneContractAsReturnContractButton record={props?.data}/>
      <EntityLink record={props?.data?.protocol} icon={<SettingsIcon/>} title={"Protokoll"}>Zur Auftragsnachbearbeitung</EntityLink>
    </DefaultEditActions>
  );
}

const ContractTitle = ( { record } ) => {
  return <span>Auftrag {record ? `"${record.auftragsnummer}"` : ""}</span>;
};

const ContractEdit = ( props ) => {
  return <Edit
    actions={<ContractEditActions allowNew={true} {...props} />}
    title={<ContractTitle/>}
    {...props}
  >
    <ContractForm isCreate={false} toolbar={<ContractToolbar isCreate={false}/>}/>
  </Edit>
}

const ContractCreate = ( props ) => {
  return <Create
    actions={<DefaultEditActions allowNew={true} {...props} />}
    title="neuen Auftrag anlegen"
    {...props}
  >
    <ContractForm isCreate={true} toolbar={<ContractToolbar isCreate={true}/>}/>
  </Create>
}

export { ContractEdit, ContractCreate };

import React from "react";
import { DeleteForever } from "@material-ui/icons";
import { DeleteButton as DButton } from "react-admin";

export const DeleteButton = React.memo(({ record, icon = <DeleteForever />, ...props }) => {
  const { style } = props
  if( !record ) {
    return null;
  }


  return <DButton record={record} icon={icon} style={style ?? {}} {...props} />;
});

import DatePicker, { registerLocale, setDefaultLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';
import { FormDataConsumer } from "react-admin";
import React from "react";
import { useForm } from "react-final-form";
registerLocale('de', de)
setDefaultLocale('de')

export default ( { fieldName = "deliveryDate" } ) => {
  const { change, batch } = useForm();
  let parsedDeliveryDate = ''

  const parseDate = (date) => {
    if( date === undefined || date === null || !date ) {
      return ''
    }

    if( date instanceof Date ) {
      const parsedDate = new Date(date)
      parsedDate.setHours( 12 )
      return parsedDate
    }

    const [day, month, year] = date.split('.');

    // Create a new Date object with the parsed values
    parsedDeliveryDate = new Date();
    if (day && month && year) {
      parsedDeliveryDate = new Date(`${year}-${month}-${day}`);
    }

    parsedDeliveryDate.setHours( 12 )
    return parsedDeliveryDate
  }

  function getValueByPath(obj, path) {
    const parts = path.split('.'); // Split the path into its components
    let value = obj;

    for (let part of parts) {
      if (part.includes('[')) { // If the part contains brackets, it's an array access
        const index = parseInt(part.match(/\d+/)[0]); // Extract the index
        const key = part.split('[')[0]; // Extract the key
        value = value[key][index]; // Access the array element
      } else {
        value = value[part]; // Access the object property
      }
    }

    return value;
  }

  return <FormDataConsumer>
    {({ formData }) => {
      return (
        <DatePicker className="MuiInputBase-input MuiFilledInput-input MuiInputBase-inputMarginDense MuiFilledInput-inputMarginDense"
                    dateFormat="dd.MM.yyyy"
                    selected={parseDate(getValueByPath(formData, fieldName))}
                    source={fieldName}
                    onChange={ (date) => {
                      date.setHours( 12 )
                      const day = date.getDate();
                      const month = date.getMonth() + 1;
                      const year = date.getFullYear();
                      const formattedDate = `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
                      batch(() => {
                        change( fieldName, formattedDate );
                        change( `${fieldName}Raw`, date );
                      } )
                      // formData[ `${fieldName}` ] = formattedDate
                      // formData[ `${fieldName}Raw`] = date
                    }}

        />
      );
    }}
  </FormDataConsumer>
}


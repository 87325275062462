import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import { Field } from "react-final-form";
import React from "react";

registerLocale( "de", de );
setDefaultLocale( "de" );

export const parseDate = ( date ) => {
  if( !date ) {
    return null;
  }

  if( date instanceof Date ) {
    return new Date(date.getTime());
  }

  if( typeof date === "string" ) {
    const readableFormat = /^\d{1,2}\.\d{1,2}\.\d{4}$/;
    const isoFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?(?:Z|[+-]\d{2}:\d{2})?$/;

    if( readableFormat.test( date ) ) {
      const [day, month, year] = date.split( "." );
      const parsedDate = new Date( `${year}-${month}-${day}` );
      parsedDate.setHours( 12 );
      return parsedDate;
    } else if( isoFormat.test( date ) ) {
      return new Date( date );
    } else {
      console.error( `Could not parse date: ${date}` );
    }
  }

  return null;
};

export default ( { fieldName, label } ) => {
  return (
    <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth">
      <div style={{ "zIndex": "2" }} className="MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-marginDense MuiFilledInput-marginDense">
        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-filled MuiFormLabel-filled">{label}</label>
        <Field name={fieldName}>
          {( { input: { value, onChange } } ) => (
            <DatePicker
              className="MuiInputBase-input MuiFilledInput-input MuiInputBase-inputMarginDense MuiFilledInput-inputMarginDense"
              dateFormat="dd.MM.yyyy"
              selected={parseDate( value )}
              onChange={(date) => {
                date.setHours(12)
                onChange(date)
              }}
            />
          )}
        </Field>
      </div>
    </div>

  );
};

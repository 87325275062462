import { TextInput } from "react-admin";
import * as React from "react";
import PropTypes from "prop-types";

const convertStringToNumber = (value) => {
  const float = parseFloat( value );
  return isNaN( float ) ? 0.00 : float;
};

const NumberInput = ( { label = '', source, parse, helperText, step, min, max, required, allowEmpty, defaultValue, inputProps, ...rest } ) => {
  const props = { step, min, max, ...inputProps }
  return <TextInput
    label={label}
    source={source}
    parse={parse}
    format={value => String( value ).replace( ',', '.' )}
    inputProps={props}
    required={required}
    clearAlwaysVisible={true}
    helperText={helperText}
    allowEmpty={allowEmpty}
    defaultValue={defaultValue}
    type="number"
    {...rest}
  />
}

NumberInput.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string,
  parse: PropTypes.func,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  helperText: PropTypes.any,
  min: PropTypes.oneOfType( [PropTypes.string, PropTypes.number] ),
  max: PropTypes.oneOfType( [PropTypes.string, PropTypes.number] ),
  step: PropTypes.oneOfType( [PropTypes.string, PropTypes.number] ),
}
NumberInput.defaultProps = {
  fullWidth: true,
  min: 0,
  max: '',
  step: 1,
  required: false,
  allowEmpty: false,
  helperText: false,
  parse: convertStringToNumber,
  defaultValue: null,
  inputProps: {}
}


export default NumberInput;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import EntityLink from "../../utils/components/EntityLink";
import SettingsIcon from "@material-ui/icons/Settings";
import EditIcon from '@material-ui/icons/Edit';
import UserSettings from "../../utils/components/UserSettings";
import { ExportPDFButton } from "../../contracts/components/ListButtons";
import * as ButtonStyles from "../../utils/components/styles/ButtonStyles";
import { TextField } from "@material-ui/core";

// Create a custom styles hook
const useStyles = makeStyles({
                               inputRoot: {
                                 minWidth: '250px', // Set the minimum width
                                 backgroundColor: 'rgba(255,255,255,0.85)', // Set the background color
                                 color: '#000',
                                 padding: '0 0.5rem',
                                 borderBottom: '0 none',
                                 '& .MuiInput-underline:before': {
                                   display: 'none'
                                 },
                                 '& .MuiInput-underline:after': {
                                   display: 'none'
                                 },
                                 '&:focus-within': {
                                   backgroundColor: 'rgba(255,255,255,1)', // Set the background color to fully opaque when focused
                                 },
                               },
                               autocomplete: {
                                 marginRight: '1rem'
                               }
                             });

const CustomPaper = (props) => (
  <Paper {...props} style={{ width: '500px' }} />
);

const t = UserSettings()?.openLinksInNewTab ? '_blank' : '_self';

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
}

const CustomAutocompleteInput = () => {
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState( false);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);



  const fetchContracts = async (nextInput, controller) => {
    const token = localStorage.getItem("token");
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/contracts/search?customSearchFilter=${nextInput}&specialFilter=&itemsPerPage=25`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        signal: controller.signal // Abbruchsignal hinzufügen
      });

      if (!response.ok) throw new Error("Fehler beim Abruf");

      const contracts = await response.json();
      if (contracts.hasOwnProperty('hydra:totalItems') && contracts['hydra:totalItems'] > 0) {
        setOptions(Array.isArray(contracts['hydra:member']) ? contracts['hydra:member'] : []);
      } else {
        setOptions([]);
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Fetch-Fehler:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetch = useCallback(debounce((input) => {
    if (controllerRef.current) {
      controllerRef.current.abort(); // Alten Request abbrechen
    }

    const newController = new AbortController();
    controllerRef.current = newController;
    fetchContracts(input, newController);
  }, 500), []);

  const controllerRef = useRef(null);

  useEffect(() => {
    if (input) {
      debouncedFetch(input);
    }
  }, [input]);


  const filterOptions = (options, state) => options;

  return (
    <Autocomplete
      open={open}
      loading={loading}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      getOptionLabel={(option) => option['auftragsnummer'] || ''}
      filterOptions={filterOptions}
      className={classes.autocomplete}
      noOptionsText={'Keine Aufträge gefunden'}
      loadingText={`Suche nach ${input}...`}
      renderOption={(option) => (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            {option.auftragsnummer}
          </Grid>
          <Grid item xs={4}>
            {option.licensePlate}
          </Grid>
          <Grid item xs={4} style={{display: "flex"}}>
            <EntityLink title={"AE3"} label={""} icon={<EditIcon />} record={option} style={ButtonStyles.noMargin} target={t}/>
            <EntityLink record={option?.protocol} icon={<SettingsIcon/>} title={"Auftragsnachbearbeitung"} style={ButtonStyles.noMargin}/>
            <ExportPDFButton
              record={option}
              style={ButtonStyles.noMargin}
              selectOnClick={false}
              showRegenerate={false}
            />
          </Grid>
        </Grid>
      )}
      renderInput={( params ) =>
        <div ref={params.InputProps.ref}>
          <TextField
            type="text"
            placeholder={'Auftragssuche..'}
            {...params.inputProps}
            className={classes.inputRoot}
            onChange={( event ) => {
              params.inputProps.onChange( event );
              setInput( event.target.value );
              setOpen( event.target.value !== '' );
            }}
          />
        </div>
      }
      PaperComponent={CustomPaper}
    />
  );
};

export default CustomAutocompleteInput;

import EntityList from "./LogList";
import { EntityCreate, EntityEdit, EntityShow } from "./LogEdit";

const Logs = {
  name: "logs",
  options: {
    label: "Logs"
  },
  list: EntityList,
  show: EntityShow,
  //#edit: EntityEdit,
  //create: EntityCreate,
}

export const LogArchive = {
  name: "log_archives",
  options: {
    label: "Log Archiv"
  },
  list: EntityList,
  show: EntityShow,
  //#edit: EntityEdit,
  //create: EntityCreate,
}


export default Logs;

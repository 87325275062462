import * as React from "react";
import { Create, Edit, FormWithRedirect, SimpleForm } from "react-admin";
import { InputGuesser } from "@api-platform/admin";
import DefaultEditActions from "../utils/DefaultEditActions";
import { BooleanInput, TextInput } from "../utils/components";
import { CustomerReferenceInput } from "../customers/components/CustomerReferenceInput";
import { FormTabContainerBox, InputContainer, ParagraphBox, ParagraphHeadline, Spacer } from "../utils/components/FormElements";
import { Box, Card } from "@material-ui/core";
import EntityToolbar from "../utils/components/Toolbar";
import CountrySelectInput from "../utils/components/CountrySelectInput";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../theme";

const useStyles = makeStyles(theme => ( {
  card: {
    width: "100%",
    maxWidth: "1024px",
    minWidth: "550px",
    margin: "1rem"
  },
  form: {
    width: "100%"
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  }
}))
const EntityForm = (props) => {

  const classes = useStyles();
  return <FormWithRedirect
    {...props}
    render={(formProps) => {
      return <form autocomplete="off">
        <Box className={classes.formContainer}>
          <Card className={classes.card}>
            <ParagraphBox>
              <FormTabContainerBox flex={1} minWidth={"550px"}>
                <TextInput source={"protocol"} label={"Protokoll"} fullWidth />
                <CustomerReferenceInput source={"customer"} label={"Kunde"} fullWidth />
              </FormTabContainerBox>

            </ParagraphBox>
          </Card>

        </Box>
        <EntityToolbar {...formProps} />
      </form>
    }}
  />
}

const EntityTitle = ({ record }) => {
  return <span>Rechnungsanschrift {record ? `"${record.firma}"` : ""}</span>;
};

const EntityEdit = (props) => (
  <Edit
    actions={<DefaultEditActions allowNew={true} {...props} />}
    title={<EntityTitle />}
    component={"div"}
    {...props}
  >
    <EntityForm />
  </Edit>
);

const EntityCreate = (props) => (
  <Create
    actions={<DefaultEditActions allowNew={true} {...props} />}
    title={"Neuen Eintrag anlegen"}
    component={"div"}
    {...props}
  >
    <EntityForm {...props} style={{ width: "100%" }} />
  </Create>
);

export { EntityEdit, EntityCreate };

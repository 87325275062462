import React, { useState } from "react";
import { Button, FormWithRedirect, SaveButton, SaveContextProvider, SelectInput, useDataProvider, useNotify } from "react-admin";
import GlobalLoading from "../../../utils/components/GlobalLoading";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import IconCancel from "@material-ui/icons/Cancel";
import { defaultButtonStyle } from "../../../utils/components/styles/ButtonStyles";
import { AddNotesButton } from "./AddNotesButton";

export const SetContractTypeButton = ( { record } ) => {
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const onSave = async ( values ) => {
    setLoading(true );
    dataProvider
      .update( "contracts", {
        id: record.contractDetails.contract['@id'],
        data: {
          contractType: values.contractType
        }
      } )
      .then( ( response ) => {
        // success side effects go here
        notify( "Auftragstyp erfolgreich gewechselt", { type: "success" } );
        dataProvider.getOne(
          "contract_avis",
          { id: record.id, }
        )
        setLoading( false );
        setShowDialog( false );
      } )
      .catch( ( error ) => {
        // failure side effects go here
        notify( `Error: ${ error.message }`, { type: "warning" } );
        setLoading( false );
      } );
  }

  return <>
    {loading && <GlobalLoading/>}
    <FormWithRedirect
      resource="contracts"
      save={ onSave }
      render={( { handleSubmitWithRedirect, pristine, saving } ) => {
        return <SaveContextProvider>
          <Dialog fullWidth open={showDialog} onClose={() => setShowDialog( false )} maxWidth={"xs"}>
            <DialogTitle>Auftragstyp wechseln</DialogTitle>

            <DialogContent>
              <SelectInput
                label={"Auftragstyp"}
                source={"contractType"}
                choices={[
                  { id: "PICKUP", name: "Abholung" },
                  { id: "DELIVERY", name: "Zustellung" },
                ]}
                initialValue={record?.contractType}
                fullWidth
              />
            </DialogContent>

            <DialogActions>
              <Button label="ra.action.cancel" onClick={() => setShowDialog( false )}>
                <IconCancel/>
              </Button>
              <SaveButton
                handleSubmitWithRedirect={ handleSubmitWithRedirect }
                pristine={ pristine }
                saving={ loading }
              />
            </DialogActions>
          </Dialog>

          <Button
            style={defaultButtonStyle}
            title={"Typ festlegen"}
            onClick={() => setShowDialog( true )}
            variant="text"
            label="Typ festlegen"
            // children={<NotesIcon/>}
          />
        </SaveContextProvider>
      }} />
  </>
}

import React from "react";
import { ReferenceField, TextField } from "react-admin"
import HotList from "../utils/components/HotList";
import { filter } from "./components/Filter";

const EntityList = ( props) => (
  <HotList {...props} filters={filter} allowCreate={true} title={"Kundenprotokolle"}>
      <TextField source={"protocol"} label={"Protokoll"}/>
      <ReferenceField reference="customers" source={"customer"} label={"Kunde"}>
        <TextField source="firma"/>
      </ReferenceField>
  </HotList>
);

export default EntityList;

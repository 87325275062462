import ContractAvisList from "./ContractAvisList";

const ContractAvis = {
  name: "contract_avis",
  options: {
    label: "Avisierung",
  },

  list: ContractAvisList,
};

export default ContractAvis;

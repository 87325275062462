import { Box, Card, Typography } from "@material-ui/core";
import { ParagraphBox, ParagraphHeadline } from "../../../utils/components/FormElements";
import { transformIRI } from "../../../contracts/components/Addresses";
import { useForm } from "react-final-form";
import React, { useEffect, useState } from "react";
import { getAddressData } from "../../../utils/request";
import { AddressReferenceInput, translateAddressLabel } from "../../../address/components/AddressReferenceInput";
import AutocompleteInput from "../../../utils/components/AutocompleteInput";
import TextInput from "../../../utils/components/TextInput";
import { validateRequired } from "../../../utils/validators/DefaultValidators";
import { BooleanInput } from "react-admin";
import DatePicker from "../../../utils/components/DatePicker";
import TimeRangeInputAlternative from "../../../utils/components/TimeRangeInputAlternative";

export default ( props ) => {
  const { onFocus, filter } = props;
  const { change, batch, getState } = useForm();
  const [isBestandsadresse, setIsBestandsadresse] = useState( false );
  const [bestandsadresse, setBestandsadresse] = useState( null );

  useEffect( () => {
    if( isBestandsadresse ) {
      change( "deliveryLocationIsSet", true );
    } else {
      change( "deliveryLocationIsSet", false );
    }
  } );

  const handleAddressChange = async( customerId ) => {
    const newAddress = await getAddressData( customerId );

    // Change fields value to new address
    batch( () => {
      change( "deliveryLocation.firma", newAddress.firma );
      change( "deliveryLocation.strasse", newAddress.strasse );
      change( "deliveryLocation.ort", newAddress.ort );
      change( "deliveryLocation.ansprechparter1", newAddress.ansprechparter1 );
      change( "deliveryLocation.anspr1email", newAddress.anspr1email );
      change( "deliveryLocation.anspr1telefon", newAddress.anspr1telefon );
      change( "deliveryTimeFrom", newAddress.startzeit );
      change( "deliveryTimeTo", newAddress.endzeit );
    } );

    setBestandsadresse( newAddress );
    setIsBestandsadresse( true );
    change( "deliveryLocation.saveLocation", false )
  };

  const handleTextInputChange = ( prefix ) => {
    const fields = ['firma', 'strasse', 'ort', 'ansprechparter1', 'anspr1telefon', 'anspr1email']
    const state = getState()

    if( bestandsadresse ) {
      setIsBestandsadresse( true )
      for( let field of fields ) {
        // const value = formData[prefix][field];
        const value = state.values[ prefix ][ field ] ?? ''
        if( bestandsadresse && bestandsadresse[ field ] !== value ) {
          setIsBestandsadresse( false )
          break
        }
      }
    } else {
      setIsBestandsadresse( false )
    }
  };


  return (
    <Card className={props.classes.card}>
      <ParagraphHeadline>Zustelladresse</ParagraphHeadline>

      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <AddressReferenceInput
            source={"deliveryLocation.@id"}
            label={"Bestandskontakt"}
            filter={filter}
            format={( v ) => transformIRI( v )}
            onChange={( address ) => {
              return address ? handleAddressChange( address ) : null;
            }}
            onFocus={onFocus}
          >
            <AutocompleteInput
              optionText={( record ) => translateAddressLabel( record )}
              source={"deliveryLocation.firma"}
            />
          </AddressReferenceInput>
        </Box>
        <Box flex={1}>
          <TextInput
            source={"deliveryLocation.firma"}
            fullWidth
            label={"Name / Firma"}
            validate={validateRequired}
            onChange={() => handleTextInputChange( 'deliveryLocation' )}

          />
        </Box>

        <Box flex={1} mr="0.5em" display={"none"}>
          <BooleanInput
            label={"Bestandsadresse"}
            source={"deliveryLocationIsSet"}
            onChange={( event ) => handleTextInputChange( 'deliveryLocation' )}
          />
        </Box>
      </Box>

      <Box>
        <Box>
          <Box display="flex">
            <Box flex={4} mr="0.5em">
              <TextInput
                source={"deliveryLocation.strasse"}
                fullWidth
                label={"Straße"}
                onChange={() => handleTextInputChange( 'deliveryLocation' )}
                validate={validateRequired}
                onFocus={onFocus}
              />
            </Box>

            <Box flex={4}>
              <TextInput
                source={"deliveryLocation.ort"}
                label={"Ort"}
                fullWidth
                onChange={() => handleTextInputChange( 'deliveryLocation' )}
                validate={validateRequired}
                onFocus={onFocus}
              />
            </Box>
          </Box>

          <Box display={"flex"}>
            <Box flex={1}>
              <TextInput
                label={"Ansprechpartner"}
                source={"deliveryLocation.ansprechparter1"}
                onChange={() => handleTextInputChange( 'deliveryLocation' )}
                fullWidth
              />
            </Box>
          </Box>

          <Box display={"flex"}>
            <Box flex={1} mr="0.5em">
              <TextInput
                source={"deliveryLocation.anspr1telefon"}
                onChange={() => handleTextInputChange( 'deliveryLocation' )}
                label={"Telefon"}
                fullWidth
              />
            </Box>
            <Box flex={1} ml="0.5em" mr={"0.5em"}>
              <TextInput
                source={"deliveryLocation.anspr1email"}
                onChange={() => handleTextInputChange( 'deliveryLocation' )}
                label={"E-Mail"}
                fullWidth
              />
            </Box>
            <Box flex={1} ml="0.5em">
              <TextInput
                source={"bringemail2"}
                fullWidth
                label={"E-Mail 2"}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <ParagraphBox>
        <BooleanInput
          source={"deliveryLocation.saveLocation"}
          label={"Adresse für weitere Aufträge speichern"}
          disabled={isBestandsadresse} />
      </ParagraphBox>

      <TextInput
        multiline
        fullWidth
        source={"bringinfos"}
        label={"Infos"}
      />
      <Typography variant="h6" gutterBottom>
        Datum & Uhrzeit Zustellung
      </Typography>

      <Box display="flex">
        <Box flex={1}>
          <DatePicker fieldName="deliveryDate" label={"Zustelldatum"}/>
        </Box>
        <Box flex={1} ml="0.5rem">
          <TimeRangeInputAlternative record={{
            pickupTimeFrom: '',
            pickupTimeTo: '',
          }} sourceFrom={"deliveryTimeFrom"} sourceTo={"deliveryTimeTo"} />
        </Box>
      </Box>
    </Card>
  );
}

import { BooleanInput, SearchInput, SelectInput } from "react-admin";
import React from "react";
import SelectArrayInput from "../../utils/components/SelectArrayInput";
import { CustomerReferenceArrayInput } from "../../customers/components/CustomerReferenceInput";
import CustomBooleanInput from "../../utils/components/CustomBooleanInput";

export const avisFilter = [
  <SearchInput
    placeholder="Auftrag, FIN, Fahrer"
    source="specialFilter"
    style={{ minWidth: "250px", marginTop: "0" }}
    alwaysOn
  />,

  <SearchInput
    placeholder="Kennzeichen"
    source="licensePlateFilter"
    style={{ width: "150px", marginTop: "0" }}
    alwaysOn
  />,

  <SelectArrayInput label="Status"
               source="status"
               choices={[
                 { id: 'pending', name: 'Offen' },
                 { id: 'first_contact', name: 'Erstkontakt' },
                 { id: 'awaiting_response', name: 'Rückmeldung ASP abwarten' },
                 { id: 'doing', name: 'In Bearbeitung' },
                 { id: 'confirmed', name: 'Termin bestätigt' },
               ]}
               emptyText={"Alle anzeigen"}
               alwaysOn />,

  <SelectInput label="Auftragstyp"
               source="contract.contractType"
               choices={[
                 { name: 'Abholung', id: 'PICKUP' },
                 { name: 'Zustellung', id: 'DELIVERY' },
               ]}
               emptyText={"Alle anzeigen"}
               alwaysOn />,

  <CustomerReferenceArrayInput label="Kunden" source="contract.auftraggeber" alwaysOn/>,
  <BooleanInput label="U4"
                title={"Abgeschlossen"}
                options={{ size: "small" }}
                source="contract.abgeschlossen"
                parse={v => v ? '1' : '0'}
                format={v => v === '1'}
                alwaysOn/>,

  <BooleanInput label="Storniert"
                title={"Storniert"}
                options={{ size: "small" }}
                source="contract.isStorniert"
                parse={v => v ? '1' : '0'}
                format={v => v === '1'}
                alwaysOn/>,
]

export const avisFilterDefaultValues = {
  "status": ['pending', 'first_contact', 'awaiting_response', 'doing'],
  "contract": {
    "isStorniert": '0',
    "abgeschlossen": '0',
  }
}

import {
  AutocompleteInput,
} from "react-admin";
import ReferenceInput from "../../utils/components/ReferenceInput";


export const CustomerDepartmentReferenceInput = ( props ) => {
  const { filter, onSelect, customer } = props

  let filterValues = {
    customer: customer !== null && typeof customer === "object" && customer.hasOwnProperty('@id' ) ? customer['@id'] : customer,
    ...filter
  }


  return <ReferenceInput
    label={"Filiale / Abteilung"}
    reference={"customer_departments"}
    // allowEmpty={true}
    fullWidth
    resettable
    filter={filterValues}
    format={( v ) =>
      v !== null &&
      typeof v === "object" &&
      v.hasOwnProperty( "@id" )
      ? v[ "@id" ]
      : v
    }
    filterToQuery={( searchText ) => ( { _name: searchText } )}
    perPage={200}
    helperText={false}
    {...props}
  >
    <AutocompleteInput
      optionText={"name"}
      source={"name"}
      onSelect={onSelect}
    />
  </ReferenceInput>
}

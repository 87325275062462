import React, { memo, useState } from "react";
import { useDataProvider, useNotify, useRedirect, useRefresh } from "react-admin";
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from "@material-ui/core";
import { useRecordContext } from "ra-core";
import SaveIcon from "@material-ui/icons/Save";
import { useForm, useFormState } from "react-final-form";
import { Blue, DisabledBlue, DisabledGreen, Green, LightBlue, LightPink } from "../../utils/components/styles/Colors";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import BooleanInput from "../../utils/components/BooleanInput";
import { darken, lighten, makeStyles } from "@material-ui/core/styles";
import { parseDate } from "../../utils/components/DatePicker";
import Button from "@material-ui/core/Button";

const stripFollowUpContract = ( formData, createBundleWith = null ) => {
  return {
    ...formData,
    createBundleWithContract: createBundleWith,
  }
}

const useStyles = makeStyles( {
  saveButton: {
    padding: "6px 16px",
    fontSize: "0.875rem",
    marginRight: "0.75rem",
    transition: "background-color 0.3s",
    color: "white",
    backgroundColor: ({ background = Green }) => background,
    '&:hover': {
      backgroundColor: ({ background = Green }) => darken(background, 0.2),
    },
  },
  disabledSaveButton: {
    backgroundColor: ({ background = Green }) => lighten(background, 0.2),
  },
  dialogContentText: {
    backgroundColor: LightBlue,
    padding: '10px 15px',
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
  },
  exclamationIcon: {
    marginRight: "10px",
  },
} )

const StyledSaveButton = ( props ) => {
  const { dirty } = useFormState();
  const label = props.label ?? ( props.isCreate ? "Auftrag erstellen" : "Speichern" );

  let background = dirty ? Blue : Green;
  const classes = useStyles({ background });


  return (
    <Button
      title={label}
      color={"primary"}
      children={<SaveIcon style={{ fontSize: '18px' }}/>}
      variant={"contained"}
      className={`${classes.saveButton} ${props.disabled ? classes.disabledSaveButton : ''}`}
      {...props}
    >
      {label}
    </Button>
  );
};

const StyledDialogContentText = memo( ( { bgColor = LightBlue, exclamationMark = false, boxProps = {}, ...props } ) => {
  const classes = useStyles();
  return (
    <DialogContentText className={classes.dialogContentText}>
      <Box display={"flex"} alignItems={"center"} {...boxProps}>
        {exclamationMark && <ErrorOutlineIcon className={classes.exclamationIcon}/>}
        {props.children}
      </Box>
    </DialogContentText>
  );
} );

export const CustomSaveButton = ( { saveButtonProps, ...props } ) => {
  const [open, setOpen] = useState( false );
  const [saving, setSaving] = useState( false );
  const { values } = useFormState({ subscription: { values: true, errors: true } });

  const refresh = useRefresh();
  const record = useRecordContext();

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  function getDaysBetweenPickupAndDelivery(pickupDate, deliveryDate) {
    let businessDays = 0;
    if( typeof pickupDate === 'undefined' || typeof deliveryDate === 'undefined' ) {
      return businessDays
    }

    let currentDate = parseDate(pickupDate);
    const endDate = parseDate(deliveryDate);

    while (currentDate < endDate) {
      const weekday = currentDate.getDay(); // 0 (for Sunday) through 6 (for Saturday)
      if (weekday > 0 && weekday < 6) { // If it's a weekday
        businessDays++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return businessDays;
  }

  function isSameDay( date1, date2 ) {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
  }

  const pickupDate = parseDate( values.datum ) ?? new Date()
  const deliveryDate = parseDate( values.bringdatum ) ?? new Date()

  const sameDay = isSameDay( pickupDate, deliveryDate )

  const driverSet = values.hasOwnProperty( 'driver' ) && values.driver !== null;

  const showAdvancedRetrievalWarning = values.vorholung && sameDay;
  const driverChanged = driverSet && values?.driver?.userId !== record.driver?.userId
  const showBundleWarning = !props.create && values.isPartOfBundle && "@id" in values && ( values?.isStorniert || driverChanged )
  const showFlexWarning = values.flexContract && values.vorholung && getDaysBetweenPickupAndDelivery( pickupDate, deliveryDate ) <= 2;
  const showFlexDriverWarning = values.flexContract && driverSet
  const showFlexSameDayWarning = values.flexContract && sameDay
  const showCreateAvisToggle = values.flexContract && !values.createAvis

  const showDialog = showAdvancedRetrievalWarning || showBundleWarning || showFlexWarning || showFlexDriverWarning || showFlexSameDayWarning || showCreateAvisToggle
  
  const onSave = async () => {
    if( showDialog ) {
      setOpen( true );
    } else {
      setSaving( true );
      props.isCreate ? createContract( { strippedFormData: values } ) : updateContract( { strippedFormData: values })
    }
  };

  const updateContract = ({strippedFormData}) => {
    dataProvider.update( 'contracts', {
      id: record[ '@id' ],
      data: { ...strippedFormData }
    } ).then( ( response ) => {
      notify( "Auftrag erfolgreich bearbeitet", { type: "success" } );
      setOpen( false );
      setSaving( false );
      if( props.redirect ) {
        redirect( 'list', '/contracts' );
      } else {
        refresh();
      }
    } ).catch( ( error ) => {
      notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
      setOpen( false );
      setSaving( false );
    } );
  }

  const createContract = ( { strippedFormData }) => {
    dataProvider.create( 'contracts', {
      data: {
          ...strippedFormData,
      }
    } ).then( ( response ) => {
      notify( "Auftrag erfolgreich erstellt", { type: "success" } );
      setOpen( false );
      setSaving( false );
      redirect( 'edit', `/contracts`, response.data.id );
    } ).catch( ( error ) => {
      setOpen( false );
      setSaving( false );
      notify( `Fehler beim erstellen: ${error.message}`, { type: "warning" } );
    } );
  }

  const onSaveModal = async( formData ) => {
    setSaving( true );
    let strippedFormData = stripFollowUpContract( values, props.createBundleWithContract );
    if( formData.createAvisConfirm ) {
      strippedFormData.createAvis = true
    }

    if( formData.deactivateBundle ) {
      for( const bundle of strippedFormData.bundles ) {
        await dataProvider.update( 'bundles', {
          id: bundle[ '@id' ],
          data: {
            active: false,
          }
        } ).then( ( response ) => {
          notify( `Fahrtpaket ${bundle.bundle} erfolgreich aufgelöst`, { type: "success" } );
        } ).catch( ( error ) => {
          notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
        } );
      }
    }

    props.isCreate ? createContract( { strippedFormData } ) : updateContract( { strippedFormData })
  };

  return [
    <StyledSaveButton key={"saveButton"} onClick={() => onSave()}
      disabled={saving}
      label={props.label ?? ( props.isCreate ? 'Auftrag erstellen' : 'Speichern' )}
      {...saveButtonProps}
    />,
    <Dialog key={"saveButtonDialog"} open={open} onClose={() => setOpen( false )}>
      <DialogContent>
        <DialogContentText>
          <Typography variant={"h6"}>Auftrag {record.auftragsnummer} speichern</Typography>
        </DialogContentText>

        {showCreateAvisToggle && <StyledDialogContentText boxProps={{ flexDirection: 'column', alignItems: 'flex-start' }}>
          Dieser Auftrag ist als Flex-Auftrag markiert. Möchten Sie eine Avisierung erstellen?
          <BooleanInput source="createAvisConfirm" label="Avisierung erstellen" initialValue={true} style={{ marginTop: '1rem' }}/>
        </StyledDialogContentText>}

        {showAdvancedRetrievalWarning && <StyledDialogContentText>
          Dieser Auftrag ist als Vorholung markiert obwohl Abholung und Zustellung am selben Tag stattfinden.
        </StyledDialogContentText>}

        {showBundleWarning && <StyledDialogContentText boxProps={{ flexDirection: 'column', alignItems: 'flex-start' }}>
          Dieser Auftrag ist Teil eines Fahrtpaketes. Wenn Sie diesen Auftrag stornieren oder an einen Fahrer vergeben, kann das Fahrtpaket aufgelöst werden:
          <BooleanInput source="deactivateBundle" label="Fahrtpaket auflösen" initialValue={true} style={{ marginTop: '1rem' }}/>
        </StyledDialogContentText>}

        {showFlexSameDayWarning && <StyledDialogContentText>
          Dieser Auftrag ist als Flex-Auftrag markiert, obwohl Abholung und Zustellung am selben Tag statt finden.
        </StyledDialogContentText>}

        {showFlexWarning && <StyledDialogContentText exclamationMark={true}>
          Flex-Aufträge die auch als Vorholung markiert sind müssen mindestens 2 Arbeitstage auseinander liegen.
        </StyledDialogContentText>}

        {showFlexDriverWarning && <StyledDialogContentText exclamationMark={true}>
          Disponierte Aufträge können nicht als Flex-Auftrag gespeichert werden.
        </StyledDialogContentText>}

      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen( false )} color="primary" label={"Abbrechen"}/>
        <StyledSaveButton onClick={() => onSaveModal( values )} disabled={saving}/>
      </DialogActions>
    </Dialog>
  ];
};

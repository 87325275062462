import React from "react";
import { DateInput, SearchInput, SelectArrayInput, SelectInput } from 'react-admin';
import { CustomerReferenceInput } from "../../customers/components/CustomerReferenceInput";

const invoiceListFilters = [
  <SearchInput
    placeholder="Nr"
    source="orderNumber"
    style={{ minWidth: "250px", marginTop: "0" }}
    alwaysOn
  />,
  <DateInput
    label="Rechnungsdatum von"
    source="createdAt[after]"
    style={{ minWidth: "200px" }}
    alwaysOn
  />,
  <DateInput
    label="Rechnungsdatum bis"
    source="createdAt[before]"
    style={{ minWidth: "200px" }}
    alwaysOn
  />,

  <SelectInput
    source={"type"}
    label={"Formulartyp"}
    choices={[
      { id: 'Rechnung', name: 'Rechnung' },
      { id: 'Gutschrift', name: 'Gutschrift' },
      { id: 'Angebot', name: 'Angebot' },
    ]} fullWidth
    alwaysOn
  />,

  <CustomerReferenceInput
    label="Kunde"
    source="customer"
    alwaysOn />

];

export { invoiceListFilters };

import MailIcon from "@material-ui/icons/Mail";
import { Button, useDataProvider, useNotify } from "react-admin";
import { useState } from "react";

export const AvisMailButton = ({ record }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [loading, setLoading] = useState( false );

  let color = 'grey';
  if( record?.parkingSpaceNotificationScheduled ) {
    color = 'orange';
    if( record?.parkingSpaceNotified ) {
      color = 'green';
    }
  }

  const onSave = async() => {
    setLoading( true );

    let data = {
      parkingSpaceNotificationScheduled: !record.parkingSpaceNotificationScheduled,
    }

    if( record.parkingSpaceNotified ) {
      if( record.parkingSpaceNotificationScheduled ) {
        data.parkingSpaceNotificationScheduled = true
      }

      data.parkingSpaceNotified = false
    }

    await dataProvider.update(
      "contract_avis",
      {
        id: record.id,
        data: {
          ...data
        }
      }
    ).then( ( response ) => {
      notify( `Stellplatzinfo ${data.parkingSpaceNotificationScheduled ? '' : 'nicht mehr '}vorgemerkt`, { type: "success" } );
      setLoading( false );
    } ).catch(( error ) => {
      notify( `Error: ${error.message}`, { type: "warning" } );
    } );
  };



  return <Button
    children={<MailIcon />}
    style={{ color: color }}
    onClick={() => onSave()}
    label={"Mail"}
  />
}

import React from "react";
import { FunctionField, ReferenceField, ShowButton, TextField } from 'react-admin';
import HotList from "../utils/components/HotList";
import { logFilter, logFilterDefaultValues, typeLabels } from "./components/LogFilter";
import { EntityShow } from "./LogEdit";
import { makeStyles, withStyles } from "@material-ui/core";

const styles = {
  rowCell: {
    verticalAlign: "middle",
  },
};

const HotCenteredList = withStyles( styles )( HotList )

const MessageLabel = ( record ) => {
  const { level, message } = record
  return `[${level}] ${message}`;
}
const UserAgentLabel = ( record ) => {
  const agent = record.data?.request?.hasOwnProperty( 'user-agent' ) ? record.data?.request[ 'user-agent' ] : 'Unbekannt'
  let label = ''
  if( agent?.includes( 'Postman' ) ) {
    label = 'Postman'
  } else if( agent?.includes( 'dart' ) ) {
    label = 'App'
  } else {
    label = 'API'
  }

  // if( agent.length > 32) {
  //   label += '...';
  // }
  return label;
}

const URLLabel = ( record ) => {
  const uri = record.data?.request?.uri ?? 'Unbekannt'
  return `${uri?.substring( 0, 64 )}${uri?.length > 64 ? '...' : ''}`
}


const TypeLabel = ( record ) => {
  // search for record.type in typeLabels
  const type = typeLabels.find( ( item ) => item.id === record.type )
  return type.name
}

const MoreInfos = ( { id, record, resource } ) => {
  const props = {
    id: id,
    record: record,
    resource: resource,
    basePath: '/logs'
  }
  return <div style={{ backgroundColor: 'rgba(0,0,0,0.025)', padding: "1rem" }}>
    <EntityShow {...props} inList={true} />
  </div>
};

const EntityList = ( props ) => {
  return <HotCenteredList sort={"createdAt"}
           sortOrder={"DESC"}
           filters={logFilter}
           filterDefaultValues={logFilterDefaultValues}
           isRowExpandable={(record) => record?.data}
           expand={<MoreInfos/>}
           bulkActionButtons={false}
           {...props}>
    <TextField source={"createdAtFormatted"} label={"Datum"} sortBy={"createdAt"}/>
    <FunctionField render={TypeLabel} label={"Typ"}/>
    <FunctionField render={MessageLabel} label={"Nachricht"}/>
    <FunctionField render={UserAgentLabel} label={"Agent"}/>
    <FunctionField render={URLLabel} label={"URL"}/>
    <TextField source={"data.request.method"} label={"Method"}/>
    <ReferenceField source={"user"} reference={"users"}>
      <TextField source={"username"} label={"Nutzer"}/>
    </ReferenceField>
    <ShowButton {...props}/>
  </HotCenteredList>
}

export default EntityList;

import React from "react";
import { ArrayInput,FormDataConsumer, SelectInput, useDataProvider } from "react-admin";
import { Box, Button, Card, CardActions, CardContent } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { InputContainer, ParagraphBox, Spacer } from "../../utils/components/FormElements";
import { makeStyles } from "@material-ui/core/styles";
import ReferenceInput from "../../utils/components/ReferenceInput";
import PriceFormIterator from "./PriceFormIterator";
import { useCardContainerStyles } from "../../utils/components/styles/CardContainerStyles";

const PriceGroupFormIterator = (props) => {
  const cardClasses = useCardContainerStyles();
  const dataProvider = useDataProvider()

  const removeButtonClickHandler = ( index ) => {
    let items = []
    props.fields.value[index].items.forEach( ( item, index ) => {
      if( item['@id'] ) {
        items.push( item['@id'] )
      }
    })

    if( items.length > 0 ) {
      dataProvider.deleteMany( "prices", {
        ids: items
      })
    }

    props.fields.remove( index )

  }

  const selectedGroups = []
  props.fields.value?.forEach( ( item, index ) => {
    selectedGroups.push( item.type )
  } )

  return (
    <Box className={cardClasses.formContainer4Cols}>

      {props.fields.map( ( item, index ) => (
        <Card className={cardClasses.card}>
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <strong>{props.fields.value[index].title}</strong>
            <Button color={"primary"} variant={"text"} style={{fontSize: "0.6rem", padding: "3px 0 0 0"}} onClick={() => removeButtonClickHandler( index )}>entfernen</Button>
          </Box>

          <ArrayInput source={`${item}.items`} label={""}>
            <PriceFormIterator type={props.fields.value[index].type}/>
          </ArrayInput>
        </Card>
       ) )}

      <PriceGroupActions selectedGroups={selectedGroups} {...props} />

    </Box>
  )
}

const PriceGroupActions = (props) => {
  const {selectedGroups} = props;
  const cardClasses = useCardContainerStyles();
  const dataProvider = useDataProvider()


  const addButtonClickHandler = async ( priceType ) => {
    dataProvider.getOne( "price_types", { id: priceType } )
                .then( ( { data } ) => {
                  let group = {
                    type: priceType,
                    title: data.name,
                    items: []
                  }
                  for( let i = 0; i < 20; i++ ) {
                    group.items.push( {
                                        distance: 50*(i+1),
                                        price: 0,
                                        type: priceType,
                                      } )
                  }
                  props.fields.push( group );
                }  )

  }

  return <Card className={`${cardClasses.cardActionButtons} ${cardClasses.columnFullWidth4Col}`}>
    <ParagraphBox style={{ maxWidth: "550px" }}>
      <InputContainer>
        <ReferenceInput
          source={"priceType"}
          reference={"price_types"} label={"Preisgruppe"}
          perPage={100}
          resettable={false}
          allowEmpty={false}
          helperText={false}
          filter={{notIn: selectedGroups.map((item) => item )}}>
          <SelectInput optionText={"name"} />
        </ReferenceInput>
      </InputContainer>

      <InputContainer display={"flex"} alignItems={"center"}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return <Button
              color={'primary'}
              label="ra.action.add"
              disabled={formData.priceType === undefined || formData.priceType === ''}
              onClick={() => addButtonClickHandler(formData.priceType)}>
              <AddIcon/> Hinzufügen
            </Button>
          }}
        </FormDataConsumer>
      </InputContainer>
    </ParagraphBox>
  </Card>
}

const useStyles = makeStyles( ( theme ) => ( {
  listItem: {
    padding: '0 5px 0 5px',
    display: 'flex',
    backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.default,
    // borderBottom: "2px solid rgba(128,128,128, 0.75)",
    '&:nth-child(odd)': {
      // boxShadow: "rgb(0,0,0,0.26) 0px 2px 20px 0px",
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.odd,
    },
    '&:hover': {
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.hover,
    },
  },

  listItemControl: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center"
  },

  currentPos: {
    display: "flex",
    alignItems: "center",
  },
  textInput: {
    margin: "8px 3px 4px 3px",
    float: 'left',
  }
} ) )


export default PriceGroupFormIterator;

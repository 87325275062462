import { Button, FormDataConsumer, useDataProvider, useNotify } from "react-admin";
import React from "react";
import CustomBooleanInput from "../../../utils/components/CustomBooleanInput";
import { Box } from "@material-ui/core";
import { useForm } from "react-final-form";

export const ResetNeuMerkerButton = ( { record, isCreate = false, ...rest } ) => {
  const notify = useNotify();
  const {change, batch} = useForm();
  const dataProvider = useDataProvider();

  // if( !record ) {
  //   return null;
  // }

  const reset = (formData) => {
    const keys = [ 'datum', 'bringdatum' ]
    const _clone = { };
    keys.forEach( key => {
      if( formData[ key ]) {
        _clone[key] = formData[ key ];
      }
    })

    dataProvider
      .update("contracts", {
        id: record.id,
        data: {
          neumerker: '1',
          angebotsMailGesendet: null,
        },
      })
      .then((response) => {
        // success side effects go here
        notify("Neu-Merker zurück gesetzt", "success");
        batch( () => {
          change( 'datum', _clone.datum );
          change( 'bringdatum', _clone.bringdatum );
        } )
      })
      .catch((error) => {
        // failure side effects go here
        notify(`Fehler beim bearbeiten: ${error.message}`, {
          type: "warning",
        });
      });
  }

  return (
      <Box display={"flex"}>
        <CustomBooleanInput source={"neumerker"} initialValue={'1'} disabled={!isCreate && record.neumerker !== '1'}/>
        <FormDataConsumer>
          {({ formData, ...consumerRest }) => {
            return !isCreate && record.neumerker !== '1' ? <Button
              title="Neumerker aktivieren"
              onClick={() => reset(formData)}
              children={<>Neumerker aktivieren</>}
              {...rest}
            /> : null
          } }
        </FormDataConsumer>
      </Box>
  );
};

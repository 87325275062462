import { useShowController, Loading, TextField as ReactTextField, ReferenceField, Show } from 'react-admin';
import { Box, Card as DefaultCard, CardContent, CardHeader, Link, Typography } from "@material-ui/core";
import DefaultEditActions from "../utils/DefaultEditActions";
import LogChangeSet from "../utils/components/LogChangeSet";
import ReactJson from "react-json-view";
import Grid from '@material-ui/core/Grid';


const EntityCreate = () => {
  return <Show></Show>
}

const EntityEdit = () => {
}

const Card = ( props ) => {
  return <DefaultCard style={{ marginBottom: "1rem" }} {...props}/>
}


const rootBoxStyles = {
  flex: "1 1 auto",
  overflow: "hidden",
  boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  borderRadius: "4px",
  color: "rgba(0, 0, 0, 0.87)",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  backgroundColor: "#FFF",
  padding: "1em"
};

const TextField = ( props ) => {
  return <Box mb={"0.25rem"}>
    <span style={{ fontSize: "0.75rem", display: "inline-block", width: '100px' }}>{props.label ?? props.source}:</span>
    <ReactTextField style={{ fontSize: '0.75rem' }} {...props} />
  </Box>
}

const EntityShow = ( { inList = false, ...props } ) => {

  const {
    basePath, // deduced from the location, useful for action buttons
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    error,  // error returned by dataProvider when it failed to fetch the record. Useful if you want to adapt the view instead of just showing a notification using the `onFailure` side effect.
    loaded, // boolean that is false until the record is available
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
    resource, // the resource name, deduced from the location. e.g. 'posts'
    version, // integer used by the refresh feature
  } = useShowController( props );

  if( loading ) {
    return <Loading/>
  }

  return (
    <>
      {!inList && <DefaultEditActions {...props} />}
      {!inList && <Card elevation={1}>
        <CardHeader
          title={<>[{record?.createdAtFormatted}] {record?.type}.{record?.level}</>}
          subheader={record?.message}/>
        <CardContent>
          {/*<Paper elevation={4} style={{ padding: "1rem" }}>*/}
          <Typography variant={"subtitle2"}>Allgemeine Infos</Typography>
          {record.data[ 'user-agent' ] && <TextField source={"user-agent"} addLabel={true} record={record.data} label={"User-Agent"}/>}
          <TextField source={"createdAtFormatted"} record={record} addLabel={true} label={"Datum"}/>

          <ReferenceField source={"user"} reference={"users"} record={record}>
            <TextField source={"username"} label={"Nutzer"}/>
          </ReferenceField>

          <TextField source={"type"} addLabel={true} record={record} label={"Typ"}/>
          <TextField source={"level"} addLabel={true} record={record} label={"Level"}/>
          {/*</Paper>*/}

        </CardContent>
      </Card>}

      <Grid container spacing={1}>
        <JSONBlock
          title={"E-Mail"}
          record={record.data}
          data={"mail"}
          fieldsToDisplay={['from', 'subject', 'recipients', 'cc', 'bcc']}
        />

        <JSONBlock
          title={"Template Daten"}
          data={"context"}
          record={record.data?.template}
          fieldsToDisplay={['template']}
        />

        <JSONBlock title={"Sonstiges"} record={record.data} data={"additionalData"}/>

        {keyExists( "changeSet", record.data ) && <Grid item xs={12}>
          <LogChangeSet changeSet={record.data.changeSet}/>
          {/*<Card>*/}
          {/*  /!*<CardHeader title={"Changeset"}/>*!/*/}
          {/*  <CardContent>*/}
          {/*    <LogChangeSet changeSet={record.data.changeSet}/>*/}
          {/*  </CardContent>*/}
          {/*</Card>*/}
        </Grid>}

        <JSONBlock title={"Alle Daten"} record={record} data={"data"} gridMd={12} gridLg={6}/>


        {record.data.content && <Grid item xs={12} md={6}>
          <HtmlCard title={"Email"} htmlContent={record.data.content}/>
        </Grid>}
      </Grid>
    </>

  );
}

const HtmlCard = ({ title, htmlContent }) => {
  return (
    <Card>
      {/*<CardHeader title={title} />*/}
      <CardContent>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </CardContent>
    </Card>
  );
};


function keyExists( key = "", obj = [] || {} ) {
  let result = false;
  if( typeof obj === "object" ) {
    result = obj.hasOwnProperty( key );
  } else if( Array.isArray( obj ) ) {
    result = key in obj;
  }
  return result
}

const JSONBlock = ( { data, title, fieldsToDisplay = [], record, gridXs = 12, gridMd = 6, gridLg = 4 } ) => {
  if( !data ) {
    return null;
  }

  let json_data = []
  if( keyExists( data, record ) ) {
    json_data = record[ data ];
  }

  if( json_data.length === 0 ) {
    return null
  }

  return <Grid item xs={gridXs} md={gridMd} lg={gridLg}>
    <Card>
      {/*<CardHeader title={title}/>*/}
      <CardContent>
        {fieldsToDisplay.length > 0 && <>
          {fieldsToDisplay.map( ( field ) => {
            let label = field;
            if( typeof field === "object" ) {
              // title={field.title}
              title = field.title
              label = field.label
            }

            return <TextField source={`${field}`} record={json_data} addLabel={true} label={`${label}`} key={`${data}.${field}`}/>
          } )}
          <br/>
        </>}

        {json_data && <ReactJson src={json_data} name={title} collapsed={1} sortKeys={true}/>}
      </CardContent>
    </Card>
  </Grid>
}

const Attachments = ( { attachments } ) => {
  if( !attachments ) {
    return null;
  }

  return <span>
    <span style={{ fontSize: "0.75rem", display: "inline-block", width: '100px' }}>Anhänge:</span>
    {attachments.map(
      item => (
        <Link href={item.url}>{item.filename}</Link>
      )
    )}
  </span>
}

export { EntityEdit, EntityCreate, EntityShow }

import { Box, Card, Typography } from "@material-ui/core";
import { ParagraphBox, ParagraphHeadline } from "../../../utils/components/FormElements";
import { transformIRI } from "../../../contracts/components/Addresses";
import { useForm } from "react-final-form";
import React, { useEffect, useState } from "react";
import { getAddressData } from "../../../utils/request";
import { AddressReferenceInput, translateAddressLabel } from "../../../address/components/AddressReferenceInput";
import AutocompleteInput from "../../../utils/components/AutocompleteInput";
import { BooleanInput } from "react-admin";
import TextInput from "../../../utils/components/TextInput";
import { validateRequired } from "../../../utils/validators/DefaultValidators";
import DatePicker from "../../../utils/components/DatePicker";
import TimeRangeInputAlternative from "../../../utils/components/TimeRangeInputAlternative";

export default ( props ) => {
  const { onFocus, filter } = props;
  const { change, batch, getState } = useForm();
  const [isBestandsadresse, setIsBestandsadresse] = useState( false );
  const [bestandsadresse, setBestandsadresse] = useState( null );

  useEffect( () => {
    if( isBestandsadresse ) {
      change( "pickupLocationIsSet", true );
    } else {
      change( "pickupLocationIsSet", false );
    }
  } );

  const handleAddressChange = async( customerId ) => {
    const newAddress = await getAddressData( customerId );

    // Change fields value to new address
    batch( () => {
      change( "pickupLocation.firma", newAddress.firma );
      change( "pickupLocation.strasse", newAddress.strasse );
      change( "pickupLocation.ort", newAddress.ort );
      change( "pickupLocation.ansprechparter1", newAddress.ansprechparter1 );
      change( "pickupLocation.anspr1email", newAddress.anspr1email );
      change( "pickupLocation.anspr1telefon", newAddress.anspr1telefon );
      change( "pickupTimeFrom", newAddress.startzeit );
      change( "pickupTimeTo", newAddress.endzeit );
    } );

    setBestandsadresse( newAddress );
    setIsBestandsadresse( true );
    change( "pickupLocation.saveLocation", false )
  };

  const handleTextInputChange = ( prefix ) => {
    const fields = ['firma', 'strasse', 'ort', 'ansprechparter1', 'anspr1telefon', 'anspr1email']
    const state = getState()

    if( bestandsadresse ) {
      setIsBestandsadresse( true )
      for( let field of fields ) {
        // const value = formData[prefix][field];
        const value = state.values[ prefix ][ field ]
        if( bestandsadresse && bestandsadresse[ field ] !== value ) {
          setIsBestandsadresse( false )
          break
        }
      }
    } else {
      setIsBestandsadresse( false )
    }
  };

  const customRecord = {
    pickupDate: '',
    pickupTimeFrom: '',
    pickupTimeTo: '',
  }

  return (
    <Card className={props.classes.card}>
      <ParagraphHeadline>Abholadresse</ParagraphHeadline>
      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <AddressReferenceInput
            source={"pickupLocation.@id"}
            label={"Bestandskontakt"}
            filter={filter}
            format={( v ) => transformIRI( v )}
            onChange={( address ) => {
              return address ? handleAddressChange( address ) : null;
            }}
            onFocus={onFocus}
          >
            <AutocompleteInput
              optionText={( record ) => translateAddressLabel( record )}
              source={"pickupLocation.firma"}
            />
          </AddressReferenceInput>
        </Box>

        <Box flex={1} mr="0.5em" display={"none"}>
          <BooleanInput
            source={"pickupLocationIsSet"}
            label={"Bestandsadresse"}
          />
        </Box>
        <Box flex={1}>
          <TextInput
            source={"pickupLocation.firma"}
            fullWidth
            label={"Name / Firma"}
            onChange={() => handleTextInputChange( 'pickupLocation' )}
            validate={validateRequired}
          />
        </Box>
      </Box>

      <Box>
        <Box display="flex">
          <Box flex={4} mr="0.5em">
            <TextInput
              source={"pickupLocation.strasse"}
              fullWidth
              label={"Straße"}
              onChange={( event ) => handleTextInputChange( 'pickupLocation' )}
              validate={validateRequired}
              onFocus={onFocus}
            />
          </Box>
          <Box flex={4}>
            <TextInput
              source={"pickupLocation.ort"}
              fullWidth
              label={"Ort"}
              onChange={( event ) => handleTextInputChange( 'pickupLocation' )}
              validate={validateRequired}
              onFocus={onFocus}
            />
          </Box>
        </Box>

        <Box display={"flex"}>
          <Box flex={1}>
            <TextInput
              source={"pickupLocation.ansprechparter1"}
              fullWidth
              label={"Ansprechpartner"}
              onChange={() => handleTextInputChange( 'pickupLocation' )}
              // validate={isCreate && validateRequired}
            />
          </Box>
        </Box>

        <Box display={"flex"}>
          <Box flex={1} mr="0.5em">
            <TextInput
              source={"pickupLocation.anspr1telefon"}
              fullWidth
              label={"Telefon"}
              onChange={() => handleTextInputChange( 'pickupLocation' )}
            />
          </Box>
          <Box flex={1} ml="0.5em" mr={"0.5em"}>
            <TextInput
              source={"pickupLocation.anspr1email"}
              fullWidth
              label={"E-Mail"}
              onChange={(  ) => handleTextInputChange( 'pickupLocation' )}
            />
          </Box>
          <Box flex={1} ml="0.5em">
            <TextInput
              source={"abholemail2"}
              fullWidth
              label={"E-Mail 2"}
            />
          </Box>
        </Box>
      </Box>

      <ParagraphBox>
        <BooleanInput
          source={"pickupLocation.saveLocation"}
          label={"Adresse für weitere Aufträge speichern"}
          disabled={isBestandsadresse} />
      </ParagraphBox>

      <TextInput
        multiline
        source={"abholinfos"}
        fullWidth
        label={"Infos"}
      />

      <Typography variant="h6" gutterBottom>
        Datum & Uhrzeit Abholung
      </Typography>


      <Box display="flex">

        <Box flex={1}>
          <DatePicker fieldName="pickupDate" label={"Abholdatum"}/>
        </Box>
        <Box flex={1} ml="0.5em">
          <TimeRangeInputAlternative record={{
            pickupTimeFrom: '',
            pickupTimeTo: '',
          }} sourceFrom={"pickupTimeFrom"} sourceTo={"pickupTimeTo"} />
        </Box>
      </Box>
    </Card>
  );
}

import { ChipField, SelectArrayInput, useGetList, LinearProgress, Error } from "react-admin";
import ReferenceArrayInput from "../../utils/components/ReferenceArrayInput";

import React from "react";


const AccessoryReferenceInput = ( props ) => {
  const { data, total, isLoading, error } = useGetList(
    'accessories',
    { page: 1, perPage: 2000 },
    { field: 'sortierung', order: 'ASC' },
    { 'voreingestellt': true },
  );

  if( isLoading ) {
    return <LinearProgress/>;
  }
  if( error ) {
    return <Error error={error}/>;
  }

  if( total ) {

    let selectedItems = []
    for( let property in data ) {
      selectedItems.push( parseInt( property.replace( '/api/accessories/', '' ) ) )
    }

    return <ReferenceArrayInput
      reference="accessories"
      source={"zubehoerArray"}
      label={"Zubeh\xf6r"}
      defaultValue={selectedItems}
      filterToQuery={( searchText ) => ( { _name: searchText } )}
      format={( value ) => value?.map( item => `/api/accessories/${item}` )}
      parse={value => value.map(item => item.split('/').pop())}
      sort={{ field: "sortierung", order: "ASC" }}
      fullWidth
      helperText={false}
      {...props}
    >
      <SelectArrayInput
        optionText={( service ) => {
          return service ? service.name : null
        }}/>
    </ReferenceArrayInput>
  } else {
    return <LinearProgress/>;
  }
}

export default AccessoryReferenceInput;

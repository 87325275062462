import React, { Fragment, useCallback } from 'react';

import { Box, Checkbox, TableCell as DefaultTableCell, Tooltip } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from '@material-ui/core/styles';
import { FunctionField, TextField } from "react-admin";
import { ContractTags } from "../../../utils/components/ListTags";
import { GmapsRouteButton } from "../../../utils/components/Buttons";
import { DriverField } from "../DriverField";
import IconChargingStation from "../../../utils/components/icons/IconChargingStation";
import ContractStatusLabel from "./ContractStatusLabel";
import PersonIcon from '@material-ui/icons/Person';
import ImageIcon from '@material-ui/icons/Image';
import { InfoButton } from "../InfoField";
import LocationTooltip from "../../../utils/components/LocationTooltip";
import ButtonList from "./ButtonList";
import { DarkRed, Red } from "../../../utils/components/styles/Colors";


export const useStyles = makeStyles( theme => ( {
  root: {
    verticalAlign: 'top',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: "rgba(128,128,128, 0.15)"
    }
  },
  cell: {
    padding: '6px 10px 6px 10px',
  }
} ), { name: 'ContractTableRow' } );

const TableCell = ( props ) => {
  const classes = useStyles();
  return <DefaultTableCell {...props} className={classes.cell}/>;
}

const StyledTableRow = ( props ) => {
  const classes = useStyles();
  return (
    <TableRow {...props} className={classes.root}>
      {props.children}
    </TableRow>
  );
};

const renderLicensePlateAndFin = (record) => {
  const missingStyles = {
    color: DarkRed,
    fontWeight: 'bold'
  };

  const licensePlateText = record.licensePlate ? record.licensePlate : 'KZ Fehlt';
  const finText = record.carFin ? record.carFin : 'FIN Fehlt';

  const licensePlate = (
    <span style={record.licensePlate ? {} : missingStyles}>
      {licensePlateText}
    </span>
  );

  const fin = (
    <span style={record.carFin ? {} : missingStyles}>
      {finText}
    </span>
  );

  let carModelText = record.carModel ?? '';

  const charCount = licensePlateText.length + finText.length + 4;
  if (charCount + carModelText.length > 45 ) {
    carModelText = carModelText.substring(0, 45 - charCount) + '...';
  }

  return <>{licensePlate} ({fin}) {carModelText}</>;
};

const ContractTableItem = ( { record, ...props } ) => {
  if( !record.id ) {
    record.id = record[ '@id' ]
  }

  const { selectedContracts, setSelectedContracts, ...rest } = props;

  const handleCheckboxChange = useCallback( ( e ) => {
    const newSelectedContracts = new Set( selectedContracts );
    if( e.target.checked ) {
      newSelectedContracts.add( record._id );
    } else {
      newSelectedContracts.delete( record._id );
    }
    setSelectedContracts( newSelectedContracts );
  }, [record, selectedContracts, setSelectedContracts] );

  const renderName = useCallback( () => {
    const textColor = record.protocol?.status > 0 ? 'grey' : '';
    return (
      <LocationTooltip record={record} location={record.pickupLocation}>
        <span style={{ color: textColor }}>
          {record.pickupDate} {record.pickupTimeFrom}-{record.pickupTimeTo} {record.pickupLocation?.ort ? `| ` : ''}<strong>{record.pickupLocation?.ort}</strong>
        </span>
      </LocationTooltip>
    );
  }, [record] );

  const renderAnschlussAuftrag = useCallback( () => {
    if( !record.anschlussAuftrag ) {
      return null;
    }
    return (
      <LocationTooltip location={record.anschlussAuftrag.deliveryLocation}>
        <span>
          {record.anschlussAuftrag.deliveryDate}{" "}
          {record.anschlussAuftrag.deliveryTimeFrom}-
          {record.anschlussAuftrag.deliveryTimeTo}
          {record.anschlussAuftrag.deliveryLocation?.ort ? " | " : ''}<strong>{record.anschlussAuftrag.deliveryLocation.ort}</strong>
          <br/>
          <FunctionField record={record.anschlussAuftrag} render={renderLicensePlateAndFin} />
        </span>
      </LocationTooltip>
    );
  }, [record] );

  const renderLocationTooltip = useCallback( () => {
    const textColor = record.protocol?.status > 2 ? 'grey' : '';
    return (
      <LocationTooltip location={record.deliveryLocation}>
        <span style={{ color: textColor }}>
          {record.deliveryDate} {record.deliveryTime}{record.deliveryLocation?.ort ? ` | ` : ''}<strong>{record.deliveryLocation?.ort}</strong>
        </span>
      </LocationTooltip>
    );
  }, [record] );


  let style = {}
  if( record.cancellationRequested ) {
    style = { backgroundColor: "rgba(255, 0, 0, 0.2)" }
  }

  return <StyledTableRow {...rest} style={style}>
    <TableCell align="left" style={{ verticalAlign: 'middle' }}>
      <Checkbox onChange={handleCheckboxChange} checked={selectedContracts.has( record._id )}/>
    </TableCell>
    <TableCell align="left" style={{ verticalAlign: 'middle' }}>
      <Box display="flex" alignItems="center" gridGap="3px">
        <strong>{record.auftragsnummer}</strong>

        {record.verzoegerteAbgabe && (
          <IconChargingStation style={{ width: "12px" }}/>
        )}
        {record.benutzer && (
          <Tooltip title={'AppUser '+record.benutzer.kuerzel+' | '+record.benutzer.name} arrow interactive><PersonIcon style={{ width: "14px" }}/></Tooltip>
        )}
      </Box>
      {record.anschlussAuftrag?.auftragsnummer}
    </TableCell>

    <TableCell style={{ verticalAlign: 'middle', textAlign: 'left' }}>
      <Box display="flex" flexWrap="wrap" justifyContent="center" alignItems={"center"} gridGap="3px">
        <FunctionField record={record} style={{ display: "flex", flexWrap: 'wrap', justifyContent: 'center', gridGap: '3px' }} render={() => <ContractTags record={record}/>}/>
        <InfoButton record={record}/>
        {!record.protocol?.allUploadsDone && <ImageIcon style={{ display: "flex", color: "#DC3545" }} title={"Es wurden noch nicht alle Bilder hoch geladen"}/>}
      </Box>
    </TableCell>

    <TableCell style={{ verticalAlign: 'middle' }}>
      <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
        <DriverField record={record}/>&nbsp;<TextField record={record} source={"contractClientAddress.kuerzel"}/>
      </div>
    </TableCell>

    <TableCell>
      <FunctionField record={record} label="Name" render={renderName}/>
      <FunctionField record={record} render={renderLicensePlateAndFin} />
    </TableCell>

    <TableCell>
      <GmapsRouteButton
        pickupLocation={record.pickupLocation}
        deliveryLocation={record.deliveryLocation}
        style={{ display: "block", textTransform: "unset", color: "black" }}>
        <>
          <Box style={{ minWidth: "75px", fontWeight: "normal", fontSize: "14px" }}>{record.totalGooglesollkm} Km</Box>
          <TextField record={record} source={"totalGooglefahrtzeit"}/>
        </>
      </GmapsRouteButton>
    </TableCell>

    <TableCell>
      <FunctionField record={record} label="locationTooltip" render={renderLocationTooltip}/>
    </TableCell>

    <TableCell>
      <FunctionField record={record} label="anschlussAuftrag" render={renderAnschlussAuftrag}/>
    </TableCell>

    <TableCell>
      <ContractStatusLabel record={record}/>
    </TableCell>

    <TableCell align="right" style={{ verticalAlign: "middle" }}>
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <ButtonList record={record}/>
      </Box>
    </TableCell>
  </StyledTableRow>
};

export default ContractTableItem;

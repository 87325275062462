import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { FormTab, ReferenceField, TextField } from "react-admin";
import React from "react";
import { Box } from "@material-ui/core";
import { useRecordContext } from "ra-core";

export default ( props ) => {
  const record = useRecordContext()

  return <FormTab label={"Historie"} {...props}>
    <Box p={1} style={{ width: "100%" }}>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell>Benutzer</TableCell>
              <TableCell>Feld</TableCell>
              <TableCell>Änderung von</TableCell>
              <TableCell>Änderung zu</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.logHistory && record.logHistory.map((logEntry, index) => {
              return <HistoryItem index={index} logEntry={logEntry} key={index}/>
            })}
          </TableBody>
        </Table>
      </Box>
    </Box>
  </FormTab>
}

const HistoryItem = ( { logEntry, index }) => {
  return <TableRow>
    <TableCell>{logEntry.logged_at}</TableCell>
    <TableCell>{logEntry.username}</TableCell>
    <TableCell>{logEntry.field}</TableCell>
    <TableCell>
      {logEntry.previous && <>
        <HistoryField source={`logHistory[${index}].previous`} logEntry={logEntry} />
      </>}
    </TableCell>
    <TableCell>
      <HistoryField source={`logHistory[${index}].data`} logEntry={logEntry} />
    </TableCell>
  </TableRow>
}

const HistoryField = ( { source, logEntry }) => {
  if( ['appUser', 'abholer'].includes(logEntry.slug) ) {
    return <ReferenceField reference="drivers" source={source}>
      <TextField source="name"/>
    </ReferenceField>
  }

  return <TextField source={source}/>
}

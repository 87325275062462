import { Button, Confirm, useDataProvider, useNotify } from "react-admin";
import MailIcon from "@material-ui/icons/Mail";
import { useState } from "react";
import { PostRequest } from "../../../utils/request/PostRequest";

export const apiEndpoint = process.env.REACT_APP_API;

export const SendMailToContactButton = ( { record } ) => {
  const [showConfirm, setShowConfirm] = useState( false )
  const [loading, setLoading] = useState( false )
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const sendMailToContact = async() => {
    setLoading( true )
    await PostRequest(
      `${apiEndpoint}${record.id}/avis_mail`,
      {}
    ).then( () => {
      notify( 'Email wurde versendet', { type: 'success' } )
      dataProvider.getOne(
        "contract_avis",
        { id: record.id, }
      ).then( (response) => {
        setShowConfirm( false )
        setLoading( false )
      })
    } ).catch( (error) => {
      notify( error.message || `Fehler beim verschieben`, { type: 'warning', multiLine: true } )
      setLoading( false )
    } )
  }

  let disabled = !record.coordination || record.aspMailSend

  let buttonText = record?.aspMailSend ? "Bereits verschickt" : "Email versenden"
  if (((record.coordination === 'pickup' || record.coordination === 'pickup_swap') && !record.contractDetails.pickupLocation?.anspr1email) ||
      ((record.coordination === 'delivery' || record.coordination === 'pickup_swap') && !record.contractDetails.deliveryLocation?.anspr1email)){
    disabled = true
    buttonText = "ASP Mail fehlt"
  }

  return [
    <Button
      label={buttonText}
      onClick={() => setShowConfirm( true )}
      children={<MailIcon/>}
      variant={"contained"}
      style={{ marginRight: 'auto' }}
      disabled={disabled}
      title={!record.coordination ? 'Bitte zuerst die Abstimmung festlegen' : 'Email versenden'}
    />,
    <Confirm
      isOpen={showConfirm}
      title="Email versenden"
      content="Möchten Sie wirklich eine Email an den Kontakt versenden?"
      onConfirm={() => sendMailToContact()}
      onClose={() => setShowConfirm( false )}
      loading={loading}
    />
  ]
}

import { makeStyles } from "@material-ui/core/styles";

export const useCardContainerStyles = makeStyles(theme => ( {
  card: {
    margin: "0.5rem",
    padding: "1rem"
  },
  columnFullWidth2Col: {
    gridColumn: "span 2",
  },
  columnFullWidth4Col: {
    gridColumn: "span 4",
  },
  cardFullWidth: {
    margin: "0.5rem",
    padding: "1rem",
    overflow: "visible",
    gridColumn: "span 2",
    [theme.breakpoints.down('lg')]: {
      gridColumn: "span 1",
    },
  },
  cardActionButtons: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
    alignSelf: "center",
    margin: "0.5rem",
    width: "fit-content",
  },
  formContainer: {
    display: "grid",
    position: "relative",
    height: "100%",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  formContainer4Cols: {
    display: "grid",
    position: "relative",
    height: "100%",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  }
}))

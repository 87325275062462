import { defaultButtonStyle } from "../../../utils/components/styles/ButtonStyles";
import { AutocompleteInput, Button, Confirm, FormWithRedirect, ReferenceInput, SaveButton, SaveContextProvider, useDataProvider, useNotify, useRefresh } from "react-admin";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import IconCancel from "@material-ui/icons/Cancel";
import React, { useState } from "react";
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { PostRequest } from "../../../utils/request/PostRequest";

export const LinkContractsButton = ( props ) => {
  const { record, style } = props;
  const [loading, setLoading] = useState( false );
  const [showDialog, setShowDialog] = useState( false );
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider()

  const buttonStyles = {
    ...defaultButtonStyle,
    ...style
  }

  if( !record ) {
    return null;
  }

  const onSave = async( values ) => {
    // console.log( values.avis );
    // return;
    setLoading( true );

    dataProvider.update(
      "contract_avis",
      {
        id: values.link,
        data: {
          link: record.id
        }
      }
    ).then( ( response ) => {
      notify( 'Erfolgreich gespeichert', { type: 'success' } );
      refresh()
      setShowDialog( false );
    } ).catch( ( error ) => {
      notify( `Error: ${error.response.data[ "hydra:description" ]}`, { type: "warning" } );
      setLoading( false )
    } )
  }


  return <>
    <FormWithRedirect
      resource="contracts"
      save={onSave}
      render={( { handleSubmitWithRedirect, pristine, saving } ) => {
        return <SaveContextProvider>

          <Dialog fullWidth open={showDialog} onClose={() => setShowDialog( false )} maxWidth={"xs"}>
            <DialogTitle>Avisierung verknüpfen</DialogTitle>

            <DialogContent>
              <ReferenceInput
                reference={"contract_avis"}
                source="link"
                label="Auftrag"
                emptyText={""}
                fullWidth
                resettable
                filter={{
                  flexContract: true,
                  custom__abgeschlossen: false,
                  provider__disable_bundles: true
                }}
                filterToQuery={( searchText ) => ( {
                  contract: {
                    auftragsnummer: searchText,
                    abgeschlossen: 0,
                  },
                } )}
                allowEmpty={true}
                helperText={false}
                {...props}
              >
                <AutocompleteInput
                  optionText="contractDetails.orderNumber"
                  disablePortal
                  PopperProps={{ placement: "bottom-start" }}
                />
              </ReferenceInput>
            </DialogContent>

            <DialogActions>
              <Button label="ra.action.cancel" onClick={() => setShowDialog( false )}>
                <IconCancel/>
              </Button>
              <SaveButton
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
                saving={loading}
              />
            </DialogActions>
          </Dialog>

          <Button
            style={buttonStyles}
            title={"Avisierung verknüpfen"}
            onClick={() => setShowDialog( true )}
            children={<LinkIcon/>}
          />
        </SaveContextProvider>
      }}/>
  </>
}

export const UnlinkContractsButton = ( props ) => {
  const { record, style } = props;
  const [loading, setLoading] = useState( false );
  const [showDialog, setShowDialog] = useState( false );
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh()

  const buttonStyles = {
    ...defaultButtonStyle,
    ...style
  }

  const removeLink = () => {
    setLoading( true );
    dataProvider.update(
      "contract_avis",
      {
        id: record.linkedAvis['@id'],
        data: {
          link: null
        }
      }
    ).then( ( response ) => {
      notify( 'Erfolgreich gespeichert', { type: 'success' } );
      refresh()
    } ).catch( ( error ) => {
      notify( `Error: ${error.response.data[ "hydra:description" ]}`, { type: "warning" } );
      setLoading( false )
    } )
  }

  return <>
    <Button
      style={buttonStyles}
      children={<LinkOffIcon/>}
      title={"Verknüpfung entfernen"}
      disabled={!record.linkedAvis}
      onClick={() => setShowDialog( true )}
    />
    <Confirm
      content={"Verbindung wirklich aufheben?"}
      onClose={() => setShowDialog( false )}
      onConfirm={() => removeLink()}
      title={`${record.contractDetails.orderNumber} / ${record?.linkedAvis?.contractDetails?.orderNumber}`}
      isOpen={showDialog}
      loading={loading}
    />
  </>
}

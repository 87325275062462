import NotesIcon from '@material-ui/icons/Notes';
import { Button, FormWithRedirect, SaveButton, SaveContextProvider, SelectInput, TextField, useDataProvider, useNotify } from "react-admin";
import GlobalLoading from "../../../utils/components/GlobalLoading";
import IconCancel from "@material-ui/icons/Cancel";
import React, { useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { defaultButtonStyle } from "../../../utils/components/styles/ButtonStyles";
import TextInput from "../../../utils/components/TextInput";
import { PostRequest } from "../../../utils/request/PostRequest";
import Typography from "@material-ui/core/Typography";
import { Spacer } from "../../../utils/components/FormElements";
import Grid from "@material-ui/core/Grid";
import IconChargingStation from "../../../utils/components/icons/IconChargingStation";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DateField from "../../../utils/components/DateField";
import Table from "@material-ui/core/Table";
import { translateStatus } from "../../ContractAvisList";
import BooleanInput from "../../../utils/components/BooleanInput";
import { SendMailToContactButton } from "./SendMailToContactButton";
import { AvisMailButton } from "./AvisMailButton";
import {DarkRed, Red} from "../../../utils/components/styles/Colors";

export const AddNotesButton = ( { setPopupOpen, ...props } ) => {
  const {record, style, label} = props
  const [loading, setLoading] = useState( false );
  const [showDialog, setShowDialog] = useState( false );
  const dataProvider = useDataProvider();
  const notify = useNotify();

  function openDialog() {
    setShowDialog(true);
    setPopupOpen(true);
  }

  function closeDialog() {
    setShowDialog(false);
    setPopupOpen(false);
  }

  if( !record ) {
    return null;
  }

  const buttonStyles = {
    ...defaultButtonStyle,
    ...style
  }

  const onSave = async( values ) => {
    setLoading( true );

    dataProvider.update(
      "contract_avis",
      {
        id: record.id,
        data: {
          ...values
        }
      }
    ).then( ( response ) => {
      notify( 'Erfolgreich gespeichert', { type: 'success' } );
      dataProvider.getOne(
        "contract_avis",
        { id: record.id, }
      ).then( () => {
        setLoading( false )
      } )
      closeDialog();
    } ).catch( ( error ) => {
      notify( `Error: ${error?.statusText}`, { type: "warning" } );
      setLoading( false )
    } )
  }

  const gridSpacing = 2;
  const modalSize = record.linkedAvis ? "lg" : "md";
  const dataGridWidth = record.linkedAvis ? 3 : 4;

  return <>
    {loading && <GlobalLoading/>}
    <FormWithRedirect
      resource="contract_avis"
      save={onSave}
      {...props}
      render={( { handleSubmitWithRedirect, pristine } ) => {
        return <SaveContextProvider {...props}>
          <Dialog fullWidth open={showDialog} onClose={() => closeDialog()} maxWidth={modalSize}>
            <DialogTitle>Avisierung für Auftrag {record.contractDetails.orderNumber}{record.linkedAvis?.contractDetails ? ` / ${record.linkedAvis.contractDetails?.orderNumber}` : ''}</DialogTitle>

            <DialogContent style={{ overflow: 'auto' }}>
              <Grid container spacing={gridSpacing}>
                <AddressGridContainer
                  title={"Abholadresse"}
                  type="pickup"
                  record={record}
                  xs={dataGridWidth}
                />

                <AddressGridContainer
                  title={record?.linkedAvis ? "Tauschadresse" : "Zustelladresse"}
                  type="delivery"
                  record={record}
                  xs={dataGridWidth}
                />

                <AddressGridContainer
                  title={"Zustelladresse"}
                  type="delivery"
                  record={record?.linkedAvis}
                  xs={dataGridWidth}
                />

                <Grid item xs={dataGridWidth}>
                  <Typography variant={"subtitle1"} style={{ fontWeight: 'bold' }}>Fahrzeugdaten</Typography>
                  {record?.linkedAvis && <Typography variant={"subtitle2"} style={{ fontWeight: 'bold' }}>Hinfahrt</Typography>}
                  <Typography variant={"body2"}>
                    {record.contractDetails.carModel}
                    <Box display={"flex"} alignItems={"center"}>
                      {record.contractDetails.electric && <>
                        <IconChargingStation style={{ width: "12px" }}/>&nbsp;
                      </>}
                      {record.contractDetails.licensePlate}
                    </Box>
                    {record.contractDetails.fin}
                  </Typography>

                  {record?.linkedAvis && <>
                    <Typography variant={"subtitle2"} style={{ fontWeight: 'bold', marginTop: '0.5rem' }}>Rückfahrt</Typography>
                    <Typography variant={"body2"} style={{ overflow: 'hidden', lineBreak: 'anywhere' }}>
                      {record?.linkedAvis?.contractDetails?.carModel}
                      <Box display={"flex"} alignItems={"center"}>
                        {record?.linkedAvis?.contractDetails?.electric && <>
                          <IconChargingStation style={{ width: "12px" }}/>&nbsp;
                        </>}
                        {record?.linkedAvis?.contractDetails?.licensePlate}
                      </Box>
                      {record?.linkedAvis?.contractDetails?.fin}
                    </Typography>
                  </>}
                </Grid>

                <Grid item xs={4}>
                  <SelectInput
                    label={"Status"}
                    source={"status"}
                    choices={[
                      { id: 'pending', name: 'Offen' },
                      { id: 'first_contact', name: 'Erstkontakt' },
                      { id: 'awaiting_response', name: 'Rückmeldung ASP abwarten' },
                      { id: 'doing', name: 'In Bearbeitung' },
                      { id: 'confirmed', name: 'Termin bestätigt' },
                    ]}

                    defaultValue={'pending'}
                    helperText={false}
                    record={record}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={4}>
                  <SelectInput
                    label={"Abstimmung"}
                    source={"coordination"}
                    choices={[
                      { id: 'pickup', name: 'Abholung' },
                      { id: 'delivery', name: 'Zustellung' },
                      { id: 'swap', name: 'Tausch' },
                      { id: 'pickup_swap', name: 'Abholung & Tausch' },
                    ]}

                    helperText={false}
                    record={record}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                  <BooleanInput source={"parkingSpace"} label={"Stellplatz Avisierung"} record={record}/>
                </Grid>

                <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                  <AvisMailButton record={record}/>
                </Grid>

                <Grid item xs={6}>
                  <TextInput multiline={true} source={"additionalNote"} label={"Weitere Notiz hinzufügen"} rows={3} record={record} fullWidth/>
                </Grid>

                <Grid item xs={6}>
                  <TextInput multiline={true} source={"note"} label={"Angepinnte Notiz"} rows={3} record={record} fullWidth/>
                  <BooleanInput label={"Wichtige Notiz"} source={"importantNote"} record={record}/>
                </Grid>

                <AvisHistoryGridItem record={record}/>
              </Grid>
            </DialogContent>

            <DialogActions>
              <SendMailToContactButton record={record} />
              <Button label="ra.action.cancel" onClick={() => setShowDialog( false )}>
                <IconCancel/>
              </Button>
              <SaveButton
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
                saving={loading}
              />
            </DialogActions>
          </Dialog>

          <Button
            style={buttonStyles}
            title={"Notiz hinzufügen"}
            onClick={() => openDialog()}
            children={<NotesIcon/>}
            label={label ?? ''}
          />
        </SaveContextProvider>
      }}/>
  </>
}

const AddressGridContainer = ( { title, type, xs, record } ) => {
  if( !record || !record.contractDetails?.hasOwnProperty(`${type}Location` ) ) {
    return null;
  }

  const address = record.contractDetails[ `${type}Location` ];

  function getDateTime() {
    let date = 'Unbekanntes Datum'
    let time = 'Unbekannte Zeit'
    if( record.contractDetails.hasOwnProperty( `${type}Date` ) ) {
      date = record.contractDetails[ `${type}Date` ]
    }

    if( record.contractDetails.hasOwnProperty( `${type}Time` ) ) {
      time = record.contractDetails[ `${type}Time` ]
    }


    return `${date} ${time}`;
  }

  const date = getDateTime();
  return <Grid item xs={xs}>
    <Typography variant={"subtitle1"} style={{ fontWeight: 'bold' }}>{title}</Typography>
    <Typography variant={"body2"}>
      {date}

      <Spacer space="0.5rem"/>

      {address.firma && <>{address.firma}<br /></>}
      {address.strasse}<br/>
      {address.ort}

      <Spacer space="0.5rem"/>

      {address.ansprechparter1 && <>
        {address.ansprechparter1}<br/>
      </>}

      {address.anspr1telefon && <>
        {address.anspr1telefon}<br/>
      </>}

      <ContactMail address={address} type={type} record={record} />
    </Typography>
  </Grid>
}

const ContactMail = ( { address, type, record } ) => {
  if( address.anspr1email ) {
    return <p style={{ whiteSpace: 'break-spaces' }}>
      {address.anspr1email.split( ';' ).join( '\n' )}
    </p>
  }

  if( (type === 'pickup' && (record.coordination === 'pickup' || record.coordination === 'pickup_swap') ) ||
      type === 'delivery' && (record.coordination === 'delivery' || record.coordination === 'pickup_swap') ) {
    return <p style={{color: DarkRed }}>Keine ASP Mail angegeben</p>
  }

  return <></>
}

const StyledCell = ( {styles, ...rest} ) => (
  <TableCell {...rest} style={{
    padding: "5px 4px",
    verticalAlign: 'top',
    ...styles
  }}/>
)

const AvisHistoryGridItem = ( { record } ) => {
  if( !record?.notes ) {
    return null;
  }

  const history = record?.notes.sort( ( a, b ) => {
    return new Date( a.date.date ) > new Date( b.date.date ) ? -1 : 1;
  } );

  return <Grid item xs={12}>
    <Typography variant={"h6"}>Änderungsverlauf</Typography>

    <Table>
      <TableHead>
        <TableRow>
          <StyledCell width={"10px"}>#</StyledCell>
          <StyledCell width={"135px"}>Datum</StyledCell>
          <StyledCell width={"50px"}>Nutzer</StyledCell>
          <StyledCell>Notiz</StyledCell>
        </TableRow>
      </TableHead>

      {history?.map( ( log, index ) => {
        let styles = {};
        if( log.user.system ) {
          styles = { color: '#878787' }
        }

        return <TableRow>
          <StyledCell styles={styles}>{history.length - index}</StyledCell>
          <StyledCell styles={styles}><DateField record={log} source={"date.date"} displayTime={true} displaySeconds={true}/></StyledCell>
          <StyledCell styles={styles}><TextField record={log} source={"user.kuerzel"}/></StyledCell>
          <StyledCell styles={styles}>
            <Typography style={{ whiteSpace: 'pre-wrap' }} variant={"body2"} dangerouslySetInnerHTML={{ __html: log.note }} />

            {( log.status?.from || log.status?.to ) && <Typography variant={"body2"}>
              <strong>Statusänderung</strong> {translateStatus( log.status?.from )} &gt; {translateStatus( log.status?.to )}<br/>
            </Typography>}
          </StyledCell>
        </TableRow>
      } )}
    </Table>

  </Grid>
}

import { Box, Grid, Typography } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: "bold",
    backgroundColor: "rgb(186, 222, 251)",
    color: "#1e1e1e",
    padding: "8px",
    borderRadius: "4px",
    fontSize: "0.875rem",
  },
  row: {
    padding: "8px",
    // borderBottom: "1px solid #bbb",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    transition: "background-color 0.075s",
    backgroundColor: "#ffffff",
    '&:nth-of-type(odd)': {
      backgroundColor: "#f9f9f9"
    },
    "&:hover": {
      backgroundColor: "#e3f2fd",
    },
  },
  highlightBox: {
    padding: "8px",
    borderRadius: "4px",
    backgroundColor: "#f0f8ff",
    fontSize: "0.875rem",
    wordBreak: "break-word",
  },
  changed: {
    backgroundColor: "#ffeb3b",
    fontWeight: "bold",
  },
  title: {
    fontWeight: "bold",
    color: "#333",
  },
  type: {
    color: "#757575",
    // fontSize: "0.65rem",
    textAlign: 'right',
    paddingRight: '5px',
    display: 'inline-block',
    width: '60px'
  }
}));

export default ({ changeSet = {} || null }) => {
  const classes = useStyles();

  if (!changeSet || Object.keys(changeSet).length === 0) {
    return null;
  }

  // sort by field name
  const sortedEntries = Object.entries(changeSet).sort(([a], [b]) => a.localeCompare(b));

  return (
    <Box style={{fontSize: '12px'}}>
      <Grid container alignItems="center" className={classes.header}>
        <Grid item xs={2}><span className={classes.type}></span>Field title</Grid>
        <Grid item xs={4}>Old Value</Grid>
        <Grid item xs={4}>New Value</Grid>
        {/*<Grid item xs={1}>Type</Grid>*/}
      </Grid>
      {sortedEntries.map(([title, data], index) => {
        const [oldValue, newValue] = data;
        return (
          <Grid container key={title} alignItems="center" className={classes.row}>
            <Grid item xs={2} className={classes.title}>
              <span className={classes.type}>[{typeof oldValue}]</span>{title}
            </Grid>
            <Grid item xs={4}>
              {print(oldValue)}
            </Grid>
            <Grid item xs={4}>
              {print(newValue)}
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

function print(result) {
  let ret = result;
  if (typeof result === "object") {
    ret = JSON.stringify(result, null, 2)
  }

  if (typeof result === "boolean") {
    ret = result ? "true" : "false";
  }

  return <pre style={{ margin: 0, whiteSpace: 'break-spaces' }}>{ret}</pre>;
}
